@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&family=Roboto:wght@400;500;700&display=swap" rel="stylesheet');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');


@layer base {

  @font-face {
    font-family: 'Canva-Sans';
    src: url('./fonts/Canva_Sans.woff') format('woff');
  }

  body, p, a {
      @apply font-body text-secondary-200 text-sm md:text-md lg:text-base
  }

  h1, h2, h3, h4, h5, h6 {
      @apply font-header text-secondary-100 leading-header
  }
}

@layer components {
  .bg-image {
    background-image: url('./pictures/bg_1.jpg');
    @apply bg-cover bg-no-repeat bg-center
  }

  .bg-image-dashboard {
    background-image: url('./pictures/bg_2.png');
    @apply bg-contain bg-no-repeat bg-bottom 
  }

  .loader {
    @apply border-8 border-green-500 border-t-transparent rounded-full w-16 h-16 animate-spin;
  }

  .page-title-background {
    background-image: url('./pictures/bg_1.jpg');
    @apply bg-cover bg-no-repeat bg-center
  }
  
  @screen lg {
    .page-title-background {
      background-image: none;
    }
  }

  .sidebar-menu-item {
    @apply block leading-[50px] hover:font-bold-header text-white
  }

  .footer-menu-item {
    @apply block leading-[35px] hover:font-bold-header
  }


  .container-form {
    @apply bg-image min-h-[100vh] text-center pb-[40px] md:pb-[50px] lg:pb-[60px]
  }

  .container-dashboard {
    @apply basis-[100%] lg:basis-[75%]  min-h-[550px] md:min-h-[650px] py-[30px] md:py-[50px] lg:py-[0px] md:pb-[50px] lg:pb-[60px]
  }

  .admin-container-dashboard {
    @apply container-dashboard px-[20px] md:px-[30px] lg:px-[80px] pb-[40px] py-[20px] md:py-[30px] lg:py-[40px]
  }

  .form-fields-prefix {
    @apply absolute left-0 ml-[25px]
  }
  

  .admin-page-header {
    @apply text-lg font-bold-header pb-[20px] md:pb-[25px] md:px-[9px] lg:px-[13px] leading-[30px] text-[#1e6c0f]
  }

  .transparent-bg {
    @apply bg-white/[.87] pb-[20px] lg:pb-[30px] pt-[3px] w-full max-w-[1200px] px-[20px] md:px-[25px] lg:px-[45px] mt-[40px] md:mt-[50px] lg:mt-[60px]
  }

  .site-logo {
    @apply w-[120px] md:w-[200px] lg:w-[255px]
  }

  .page-header {
    @apply text-2xl md:text-5xl lg:text-6xl font-extra-bold-header my-[5px] md:my-[7px] lg:my-[9px]
  }

  .secondary-header {
    @apply text-[19px] md:text-lg lg:text-xl font-semibold-header md:font-bold-header lg:font-extra-bold-header
  }

  .form-label {
    @apply font-Poppins font-semibold
  }

  .form-fields {
    @apply px-[22px] py-[13px] border-[2px] border-[#797972] my-[5px] bg-transparent placeholder:text-secondary-200
  }

  .form-error {
    @apply text-error font-semibold pb-[5px]
  }

  .register-field-container {
    @apply flex flex-wrap lg:-mx-2
  }

  .register-field {
    @apply lg:px-[18px] w-full lg:w-1/2
  }


  .profile-details-fields {
    @apply px-0 md:px-[10px] lg:px-[22px] w-full lg:w-1/2
  }


  /* Custom radio button style */
  .radio-square {
    @apply appearance-none bg-white w-[36px] h-[36px] cursor-pointer grid place-content-center relative m-0 mx-[10px]
  }
  
  .radio-membership-1 {
    @apply bg-[#8dc63e];
  }

  .radio-membership-2 {
    @apply bg-[#59b245];
  }

  .radio-membership-3 {
    @apply bg-[#126603];
  }

  .radio-square:checked::after {
    content: "\f00c"; 
    font-size: 20px;
    font-family: 'Font Awesome 5 Free'; 
    font-weight: 900;
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

}